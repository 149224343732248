<template>
    <div class="section-content" :class="{'tenant-section': !isAdmin, 'pa-0-im': isStickyHeaderHide}">
        <div class="tenant-profile-sticky" :style="{ zIndex: $vuetify.breakpoint.width > 480 ? 4 : showAside ? '2' : '3'  }" :class="{ 'scroll-window tenant-profile-shadow': hasScrollWindow, 'tenant-profile-border': !hasScrollWindow }" v-show="!isStickyHeaderHide" v-if="tenant_contact.id">
            <v-row  class="ma-0 pt-6 pb-4 d-flex align-center px-5">
                <v-col class="ma-0 pa-0 backtohistory" :class="{'col-xs-12': !$vuetify.breakpoint.xs, 'col-xs-11': $vuetify.breakpoint.xs}">
                    <hb-breadcrumb
                        v-if="$route.query.document_batch_id && $route.query.document_delivery_id"
                        :to="{
                            name: 'noticeManagerBatchViewDirect',
                            params: { document_batch_id: $route.query.document_batch_id, document_delivery_id: $route.query.document_delivery_id },
                        }"
                    >
                        {{ breadcrumbText }}
                    </hb-breadcrumb>
                    <hb-breadcrumb
                        v-else-if="$route.query.document_batch_id"
                        :to="{
                            name: 'noticeManagerBatchViewDirectBatchIdOnly',
                            params: { document_batch_id: $route.query.document_batch_id },
                        }"
                    >
                        {{ breadcrumbText }}
                    </hb-breadcrumb>
                    <hb-breadcrumb v-else @click="goBreadcrumbRoute">{{ breadcrumbText }}</hb-breadcrumb>
                </v-col>
                <div class="col-xs-1" v-if="$vuetify.breakpoint.xs">
                    <hb-btn class="mr-n3" icon :tooltip="!$vuetify.breakpoint.xs ? 'Sidebar' : ''" :class="{ 'pt-11': $route.name === 'ContactFiles'}" @click="showAside = !showAside" :active-by-default="true" :active-state-off="!showAside">mdi-table-actions-custom-1</hb-btn>
                </div>
                <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            </v-row>
            <hb-header class="px-5 sticky-miniprofile" :divider="false" :one-action="!$vuetify.breakpoint.xs" :padding="false">
                <template v-slot:left>
                    <div class="pb-2">

<!-- I made 'sms-click' prop below enabled for 'ContactOverview' page only because it previously did not work on other pages if clicked on -->
<!-- so it is better to disable it on other pages for now until someone has time to fix it, rather than leave it not working -->
<!-- if we want to enable it on all pages, then someone needs to add additional logic to handle this, I merely am fixing something else and found this problem and did a quick temporary solution -->
                        <mini-profile-view 
                            v-if="tenant_contact?.id"
                            :contact-id="tenant_contact.id"
                            :use-contact="false"
                            :contact-data="tenant_contact"
                            :show-middle-name="true"
                        />
                    </div>
                </template>
                    <template v-slot:right v-if="!$vuetify.breakpoint.xs">
                        <div>
                            <hb-btn class="mr-n3" icon :tooltip="!$vuetify.breakpoint.xs ? 'Sidebar' : ''" :class="{ 'pt-11': $route.name === 'ContactFiles'}" @click="showAside = !showAside" :active-by-default="true" :active-state-off="!showAside">mdi-table-actions-custom-1</hb-btn>
                        </div>                        
                    </template>
                </hb-header>
                <!-- <v-divider v-if="properties_with_leases.length > 1 || $route.name === 'ContactFiles'"></v-divider> -->
                <HbPropertySelector 
                    id="facility" 
                    :items="properties_with_leases" 
                    v-model="propertyView"
                    v-if="properties_with_leases.length > 1" 
                    item-value="id" 
                    item-text="name"                     
                    height="40"
                    class="pl-5"
                    :class="{ 'hb-header-select': $vuetify.breakpoint.mdAndUp }">
                    <template v-slot:selection="data">
                        <div class="hb-input-overflow" v-if="data.item.number && data.item.name && data.item.Address">
                            {{ data.item.number }} - {{ data.item.name }} - {{ data.item.Address.address }} {{
                                data.item.Address.address2 }}
                        </div>
                        <div v-else>
                            {{ data.item.name }}
                        </div>
                    </template>
                    <template v-slot:item="data">
                        <div v-if="data.item.number && data.item.name && data.item.Address">
                            {{ data.item.number }} - {{ data.item.name }} - {{ data.item.Address.address }} {{
                                data.item.Address.address2 }}
                        </div>
                        <div v-else>
                            {{ data.item.name }}
                        </div>
                    </template>
                </HbPropertySelector>
                <hb-tabs-responsive
                    v-model="activeTab"
                    @tab-clicked="tabClicked"
                    v-if="$route.name === 'ContactFiles'"
                    class="mt-2 px-5 contact-view-profile"                >
                    <template v-slot:tabs>
                        <tab
                            v-for="menuOption in menuOptions"
                            :key="menuOption.key"
                            :defaultTab="'#' + menuOption.key"
                            :label=" menuOption.label"
                        >
                        </tab>
                    </template>
                </hb-tabs-responsive>
                <hb-modal
                size="large"
                v-model="showEmailModal"
                title="Send Email"
                class="ma-0 py-0 interaction-window"
                show-help-link
                footer-off
            >
                <template v-slot:content>
                    <send-email
                        :to='tenant_contact.email'
                        :subject="subject"
                        :interactiveMode="true"
                        :onClose='onEmailClose'
                        :onChatError='onChatError'
                        @interaction_saved="onEmailClose"
                        :contactId="tenant_contact.id"
                        :textarea_height="textAreaHeight"
                        :contactEmail="contactEmailList"
                        :leases="leasesDoc"
                        :propertyid="propertyView"
                        :spaceListViewOptions='mainViewOptions'
                    > </send-email>
            </template>
          </hb-modal>
        </div>

        <div class="primary-section-content tenant-profile-primary-section-content" :class="{'pa-0 is-email-container': isStickyHeaderHide, 'pl-5':!responsiveInteraction }">
            <div ref="tenantProfileSectionContent" class="main-content pr-0" :class="{'pr-5':!responsiveInteraction}">    
                
                <router-view 
                    v-if="(tenant_contact.id && !showPaymentCyclesTab && $route.name !== 'ContactPaymentCycles') || showPaymentCyclesTab"
                    :payment_methods="payment_methods" 
                    :access="access"
                    :contact="tenant_contact" 
                    :showAside="showAside" 
                    @moveOut="setMoveOut" 
                    @merchandise="setMerchandise" 
                    :contactLeases="property_leases"
                    :isSpaceAccessCodesVendor="isSpaceAccessCodesVendor" 
                    :view="view" 
                    :pending="pending" 
                    @reset="activeTab = 'tenantdocuments'; view = 'tenantdocuments'" 
                    :property-view.sync="propertyView" 
                    @transfer="setTransfer"
                    @responsiveInteraction="responsiveInteractionAction"
                    :from-tenant-profile="true"
					ref="OverviewRef">
                </router-view>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                    class="loading"
                ></v-progress-circular>

            </div>
            <v-navigation-drawer
                v-model="showAside"
                :hide-overlay="$vuetify.breakpoint.width > 480"
                :clipped="$vuetify.breakpoint.width > 480"
                :class="{ 'attached-drawer': $vuetify.breakpoint.width > 480 }" 
                disable-resize-watcher 
                mobile-breakpoint="481"
                class="ma-0 pa-0 hb-tertiary-navigation tenant-profile-sidebar-wrapper"
                :width="showAside ? '250px' : '0px'" 
                :bottom="$vuetify.breakpoint.width <= 480" 
                :right="$vuetify.breakpoint.width >= 481"
                :app="$vuetify.breakpoint.width <= 480" 
                flat
            >
                <v-list dense style="width:100%;">
                    <v-list-item-group v-model="selection" mandatory>
                        <v-list-item exact :to="'/contacts/' + $route.params.contact_id" class="pl-5" @click="closeDrawer">
                            Tenant Profile
                        </v-list-item>
                        <v-list-item exact :to="'/contacts/' + $route.params.contact_id + '/transaction-history'" class="pl-5" @click="closeDrawer">
                            Transaction History
                        </v-list-item>
                        <v-list-item exact :to="'/contacts/' + $route.params.contact_id + '/files'" class="pl-5" @click="closeDrawer">
                            Documents
                        </v-list-item>
                        <v-list-item v-if="!$vuetify.breakpoint.xs" :to="'/contacts/' + $route.params.contact_id + '/payment-methods'" class="pl-5" @click="closeDrawer">
                            Payment Methods
                        </v-list-item>
                        <v-list-item :to="'/contacts/' + $route.params.contact_id + '/gate-access'" class="pl-5" @click="closeDrawer">
                            Gate Access
                        </v-list-item>
                        <v-list-item v-if="showPaymentCyclesTab" :to="'/contacts/' + $route.params.contact_id + '/payment-cycles'" class="pl-5" @click="closeDrawer">
                            Payment Cycles
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
        </div>

    </div>

</template>

<script type="text/babel">
    import SendMessage from '../includes/SendMessage.vue'
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    import sendEmail from '../communication_center/SendEmail.vue'
    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import MiniProfileView from '../includes/LeadIntake/MiniProfileView.vue';
import vuetify from '../../plugins/vuetify.js';

    export default {
        name: 'ContactView',
        mixins: [ notificationMixin ],
        data() {
            return {
                contact_id: '',
                contactLeases: [],
                lease:{
                    id: null,
                    rent: 0,
                    security_deposit: 0,
                    type: 'Fixed Length',
                    start_date: '',
                    end_date: '',
                    billday: '',
                    promotion_id: '',
                    send_invoice: 1,
                    notes: '',
                    late_fee: '',
                    late_fee_type: '',
                    late_fee_days: '',
                    late_fee_subsequent_days: '',
                    late_subsequent_fee: '',
                    late_fee_subsequent_type: '',
                    Unit: {
                        Address: {}
                    },
                },
                payment_methods:[], 
                showAddMaintenance:false,
                sendMessage:false,
                showDeleteConfirmation:false,
                showEditLink: false,
                showMakePayment:false,
                showMakeInvoice:false,
                showManageAccess: false,
                showMoveOut: false,
                showAside: false,
                showTransfer:false,
                showEmailModal: false,
                isStickyHeaderHide: false,
                textAreaHeight: 200,
                chatError: '',
                subject: '',
                // tenants:[],
                // activeTab: '',
                showNote: true,
                note: '',
                selection: [],
                tenant_contact: {},
                access: [],
                isSpaceAccessCodesVendor: false,
                spaceAccess: [],
                view: 'tenantdocuments',
                activeTab: 'tenantdocuments',
                menuOptions: [{
                    key: "tenantdocuments",
                    label: "Tenant Documents"
                },{
                    key: "archivedocuments",
                    label: "Archived Documents"
                },{
                    key: "documentlibrary",
                    label: "Document Library"
                }],
                propertyView: '',
                propertiesWithLeases: [],
                breadcrumbText: 'Back to Previous Page',
                pageCreated: false,
                pending: [],
                contact_changing: false,
                contactEmailList:[],
                leasesDoc:"",
                currentPropertyId:"",
                mainViewOptions:[],
                mainView: {
                    id: null,
                    Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                    }
                },
                hasScroll: false,
                hasScrollWindow: false,
                responsiveInteraction:false
            }
        },
        components:{
            Status,
            SendMessage,
            MiniProfileView,
            "send-email": sendEmail
        },
        mounted() {
            // this.checkScrollableContent();
            // window.addEventListener('resize', this.checkScrollableContent);

			setTimeout(() => {
                this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "profile-button-clicked"})
            }, 2000);
            EventBus.$on('contact_updated_transfer', this.fetchContact);
            let tenantProfileSectionContent = this.$refs['tenantProfileSectionContent']
            tenantProfileSectionContent.addEventListener('scroll', this.handleScroll);
        },
        async created (){
            EventBus.$on('hideMiniprofile', this.hideMiniProfile)
            await this.loadTenantDetails();
            EventBus.$on('refetch_data', this.loadTenantDetails);
            EventBus.$on('closeDrawer', this.closeDrawer);
        },
        beforeDestroy(){
            let tenantProfileSectionContent = this.$refs['tenantProfileSectionContent']
            tenantProfileSectionContent.removeEventListener('scroll', this.handleScroll)
        },
        destroyed(){
            EventBus.$off('payment_methods_updated', this.fetchPaymentMethods);
            EventBus.$off('contact_updated_transfer', this.fetchContact);
            EventBus.$off('contact_updated', this.fetchContact);
            EventBus.$off('lease_payment_saved', this.fetchContact);
            EventBus.$off('payment_saved', this.fetchContact);
            // EventBus.$off('recordPaymentCanceled', this.recordPaymentCanceled);
            EventBus.$off('access_pin_modified', this.fetchAccess);
            EventBus.$off('space_access_modified', this.fetchContact);
            EventBus.$off('refresh-contact-profile', this.refreshPage);
            EventBus.$off('reload_tenant_profile_leases', this.fetchContact);
            EventBus.$off('delinquency_actions_update', this.updateDelinquencyActions);
            EventBus.$off('auto_pay_enrolment', this.autoPayEnrolled);
            EventBus.$off('refetch_data', this.loadTenantDetails);
            EventBus.$off('closeDrawer', this.closeDrawer);
            EventBus.$off('hideMiniprofile', this.hideMiniProfile);
            window.removeEventListener('scroll', this.handleScroll);
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                isSuperAdmin: 'authenticationStore/isSuperAdmin',
                properties: 'propertiesStore/filtered',
                leasedProperties: 'propertiesStore/leasedProperties',
                getRefreshTenantProfile: 'tenantProfile/getRefreshTenantProfile',
                allProperties: 'propertiesStore/properties',
                spaceAccessCodesMode: 'authenticationStore/spaceAccessCodesMode',
            }),
            showSidebar(){
                return this.$route.meta.showSidebar;
            },
            
            property_leases(){
                // filters the leases that are available at the set property
                return this.contactLeases.filter(cl => !this.propertyView || cl.Unit.property_id == this.propertyView);
            },
            properties_with_leases(){
                return this.allProperties.filter(p => this.contactLeases.find(cl => cl.Unit.property_id === p.id));
            },
            computedContactTitle() {
                return (this.tenant_contact?.first ? this.tenant_contact?.first : '') + (this.tenant_contact?.middle ? ' ' + this.tenant_contact?.middle : '') + (this.tenant_contact?.last ? ' ' + this.tenant_contact?.last : '');
            },
            computedContactPhone() {
                if(this.tenant_contact.Phones && this.tenant_contact.Phones[0] && this.tenant_contact.Phones[0].phone){
                    return this.tenant_contact.Phones[0].phone;
                } else {
                    return undefined;
                }
            },
            computedContactPhoneVerified() {
                if(this.tenant_contact.Phones && this.tenant_contact.Phones[0] && this.tenant_contact.Phones[0].phone_verified && this.tenant_contact.Phones[0].phone_verified == 1){
                    return true;
                } else {
                    return false;
                }
            },
            computedContactAddress() {
                if(this.tenant_contact.Addresses && this.tenant_contact.Addresses[0] && this.tenant_contact.Addresses[0].Address){
                    const address_1 = this.tenant_contact.Addresses[0].Address.address ? this.tenant_contact.Addresses[0].Address.address : '';
                    const address_2 = this.tenant_contact.Addresses[0].Address.address2 ? this.tenant_contact.Addresses[0].Address.address2 : '';

                    let address = address_1;
                    if(address_2 != '') address += ' ' + address_2;
                    
                    address += ', ' + this.$options.filters.formatAddress(this.tenant_contact.Addresses[0].Address); 
                    
                    return address;
                } else {
                    return undefined;
                }
            },
            computedAccessCode () {
                if(this.isSpaceAccessCodesVendor && this.spaceAccess && this.spaceAccess[0] && this.spaceAccess[0].pin){
                    return this.spaceAccess[0].pin;
                } else if (!this.isSpaceAccessCodesVendor && this.access && this.access[0] && this.access[0].pin){
                    return this.access[0].pin;
                } else {
                    return undefined;
                }
            },
            computedMultipleAccessCodes() {
                if((this.isSpaceAccessCodesVendor && this.spaceAccess && this.spaceAccess.length > 1) || (!this.isSpaceAccessCodesVendor && this.access && this.access.length > 1)){
                    return true;
                } else {
                    return false;
                }
            },
            computedAccessHasError(){
                if(this.isSpaceAccessCodesVendor && (this.spaceAccess.filter(p => this.spaceAccess.find(cl => cl.late_catch === 1 || cl.hard_catch === 1)).length > 0)){
                    return true;
                } else {
                    return false;
                }
            },
            computedRepresentativeName(){
                if(this.tenant_contact.Representative) {
                    return (this.tenant_contact?.Representative?.Contact?.first ? this.tenant_contact?.Representative?.Contact?.first : '') + (this.tenant_contact?.Representative?.Contact?.last ? ' ' + this.tenant_contact?.Representative?.Contact?.last : '');
                }
                return undefined;
            },
            showPaymentCyclesTab() {
                let leases = this.tenant_contact?.Leases;
                if(leases?.length && leases.find(item => item.PaymentCycleOptions.length > 0 )) {
                    return true;
                }
                return false;
            }
        },
        methods: {
            ...mapMutations({
              setFirstContact: 'contactStore/setFirstContact',
              setPrimaryContactId: 'contactStore/setPrimaryContactId',
              setCurrentProperty: 'contactStore/setCurrentProperty',
              setRefreshTenantProfile: 'tenantProfile/setRefreshTenantProfile',
            }),                 
            ...mapActions({
                setLeasedProperties: 'propertiesStore/setLeasedProperties'
            }),
            tabClicked({tabObject}){
                let key = tabObject?.key
                this.view = key
            },
            responsiveInteractionAction(value){
                this.responsiveInteraction = value
            },
            handleScroll() {
                let tenantProfileSectionContent = this.$refs['tenantProfileSectionContent']
                const scrollPosition = tenantProfileSectionContent.scrollTop;
                this.hasScrollWindow = scrollPosition > 0;
            },
            hideMiniProfile(flag) {
                this.isStickyHeaderHide = flag && this.$vuetify.breakpoint.xs;
            },
            closeDrawer () {
                //console.log('close drawer called!.......................');
                if(this.$vuetify.breakpoint.width < 1366) {
                    this.showAside = false;
                    EventBus.$emit('hideNavigationDrawer', false);
                }
            },
            setMoveOut(payload){
                EventBus.$emit('move_out', {
                    property_id: payload.Unit.property_id,
                    contact: this.tenant_contact,
                    lease_id: payload.id,
                    unit_id: payload.unit_id
                });
            },
            setTransfer(payload) {
                EventBus.$emit('transfer', {
                    property_id: payload.Unit.property_id,
                    contact: this.tenant_contact,
                    lease_id: payload.id,
                    unit_id: payload.unit_id,
                    access: this.access,
                    space_access: this.spaceAccess,
                    is_space_access_codes_vendor: this.isSpaceAccessCodesVendor,
                });
            },
            setMerchandise(payload){
                EventBus.$emit('sell_merchandise', {
                    property_id: payload.Unit.property_id,
                    contact: this.tenant_contact,
                    lease_id: payload.id,
                    unit_id: payload.unit_id,
                    tab_type: payload.tab_type
                });
            },
            async loadTenantDetails() {
                EventBus.$emit('hide_drawer');
                await this.fetchProperties();
                await this.loadContactInfo();

                // await this.loadTenantDetails();
                
                if(this.$vuetify.breakpoint.width < 1366){
                    this.showAside = false;
                } else {
                    this.showAside = true;
                }

                EventBus.$on('delinquency_actions_update', this.updateDelinquencyActions);
                EventBus.$on('contact_updated', this.fetchContact);
                EventBus.$on('payment_methods_updated', this.fetchPaymentMethods);
                EventBus.$on('lease_payment_saved', this.fetchContact);
                EventBus.$on('payment_saved', this.fetchContact);
                // EventBus.$on('recordPaymentCanceled', this.recordPaymentCanceled);
                EventBus.$on('access_pin_modified', this.fetchAccess);
                EventBus.$on('refresh-contact-profile', this.refreshPage);
                EventBus.$on('reload_tenant_profile_leases', this.fetchContact);
                EventBus.$on('auto_pay_enrolment', this.autoPayEnrolled);

                this.pageCreated = true;
                this.setRefreshTenantProfile(false);
                await this.fetchAccess();
            },
            autoPayEnrolled(payload){
                let { err } = payload;
                let errorMessage = err?.msg;
                if(errorMessage){
                    setTimeout(() => {
                        this.showMessageNotification({ description: errorMessage });
                    }, 3000);
                }
            },
            async fetchProperties(){
                await this.setLeasedProperties(this.$route.params.contact_id);
                if(this.leasedProperties.length === 0) {
                    this.$router.push('/tenants').catch(err => {})
                }
                let property_id = this.$route.query.property_id
                let unit_id = this.$route.query.unit_id
                if(property_id && this.leasedProperties.find(lp => lp.id == property_id)) this.propertyView = property_id;
                else if(unit_id) this.propertyView = this.leasedProperties.find(lp => lp.Units?.includes(unit_id))?.id;
                else if(this.properties?.length === 1) this.propertyView = this.properties[0].id
                else this.propertyView = this.leasedProperties[0].id;                
            },
            async fetchContact(contact_id="", updateLeases=false) {
                if(contact_id && contact_id !== this.$route.params.contact_id) return;
                let r = await api.get(this, api.CONTACTS + this.$route.params.contact_id);

                this.setFirstContact(r.contact)
                this.setPrimaryContactId(r.contact.id)        
                this.setEmailList(r.contact)
                this.resetSpaceMenuOptions(r.contact)
                this.tenant_contact = r.contact;
                
                // if we are just updating the contact info, we dont need to reset these leases, otherwise there will be a dozen or so API requests for all units. 
                
                if(!contact_id || updateLeases) {
                    this.contactLeases = r.contact.Leases;
                };
        
                if(!this.contactLeases.length) return;
                if(!this.propertyView || !this.contactLeases.find(cl => cl.Unit.property_id === this.propertyView)){
                    this.propertyView = this.contactLeases[0].Unit.property_id;
                }
            },
            async fetchPending(){
                this.pending = [];
                await api.get(this, api.LEASES +  'pending', { contact_id: this.$route.params.contact_id, property_id: this.propertyView }).then( r => {
                    this.isLoaded = true;
                    this.pending = r.leases.map(l => {
                        l.start_date = moment(l.start_date, 'YYYY-MM-DD').startOf('day').valueOf();
                        l.end_date = l.end_date ? moment(l.end_date , 'YYYY-MM-DD').startOf('day').valueOf() : null;
                        l.time = 0;
                        l.contact = l.Tenants[0] ? l.Tenants[0].Contact : null;
                        l.unit = l.Unit;
                        l.task_type = 'moveIn'
                        return l;
                    });
                });
            },
            async fetchPaymentMethods(){
                if(!this.propertyView) return;
                let r =  await api.get(this, api.CONTACTS + this.$route.params.contact_id + '/payment-methods?property_id=' + this.propertyView); 
                this.payment_methods = r.payment_methods.map(pm => {
                    this.$set(pm,'editable', false);
                    return pm; 
                });
            },
            async fetchAccess(){

                if(this.isSpaceAccessCodesVendor){
                    let s = await api.get(this, api.CONTACTS + this.$route.params.contact_id + '/space-access');
                    this.spaceAccess = s.access;
                } else {
                    let r = await api.get(this, api.CONTACTS + this.$route.params.contact_id + '/access');
                    this.access = r.access;
                }
            },
            async checkIsSpaceAccessCodesVendor(){
                if(this.propertyView){
                    try {
                        let r = await api.get(this, api.PROPERTIES + this.propertyView + '/access');

                        if(r.property_access_name === 'Derrels'){
                            this.isSpaceAccessCodesVendor = true;
                        }
                    } catch (err){
                        console.log(err);
                    }
                }
            },
            viewContact(type){
                if(type === 'access'){
                    this.$router.push('/contacts/' + this.$route.params.contact_id + '/gate-access');
                } else if(type === 'payment-methods'){
                    this.$router.push('/contacts/' + this.$route.params.contact_id + '/payment_methods');
                }
            },
            goBreadcrumbRoute(){
                if(this.$route.name === 'ContactOverview'){
                    this.$router.go(-1);
                } else {
                    this.$router.push('/contacts/' + this.$route.params.contact_id);
                }
            },
            onChatError (error) {
                this.chatError = error
            },
            onEmailClose(){
              this.showEmailModal = false;
              this.subject = ''
            },
            onEmailOpen(){
              this.showEmailModal = true;
            },
            refreshPage(contact_id = ""){
                this.clearData();
                this.loadContactInfo(contact_id);
                // this.$router.go(0);
            },
            async loadContactInfo(contact_id = ""){
                if(contact_id){
                    await this.fetchContact(contact_id, true);
                }else{
                    await this.fetchContact();
                }
                await this.checkIsSpaceAccessCodesVendor();
                this.fetchAccess();
                this.fetchPending();
                this.fetchPaymentMethods();
                window.scrollTo(0,0);
            },
            updateDelinquencyActions(payload) {
                const { leases } = payload;
                const isLeaseUpdated = this.contactLeases.some(cl => leases.some(l => l.id === cl.id));
                if(isLeaseUpdated) {
                    this.fetchContact();
                }                
            },
            clearData(){
                this.contact_id = '',
                //contactLeasesPreFilter: [],
                this.contactLeases = [],
                this.lease = {
                    id: null,
                    rent: 0,
                    security_deposit: 0,
                    type: 'Fixed Length',
                    start_date: '',
                    end_date: '',
                    billday: '',
                    promotion_id: '',
                    send_invoice: 1,
                    notes: '',
                    late_fee: '',
                    late_fee_type: '',
                    late_fee_days: '',
                    late_fee_subsequent_days: '',
                    late_subsequent_fee: '',
                    late_fee_subsequent_type: '',
                    Unit: {
                        Address: {}
                    },
                },
                this.property = {},
                this.note = '';
                this.selection = [];
                this.tenant_contact = {};
                this.access = [];
                this.propertyView = '';
                // this.propertiesWithLeases = [];
                this.pending = [];
            
            },
            phoneNumberClicked(){
                this.$refs.OverviewRef?.phoneNumberClicked();
            },
            setEmailList(contactObj){
                this.contactEmailList = contactObj.Relationships.map(item => {  return {id : item.Contact.id, email: item.Contact.email +' ('+ this.getEmailType(item)+')'  } })
                if(contactObj.Military.CommandingOfficer.email)
                this.contactEmailList.push({id: contactObj.Military.contact_id, email: contactObj.Military.CommandingOfficer.email+' (Military)'})
                this.contactEmailList.unshift({id:contactObj.id,email:contactObj.email+' (Primary)'})
                },
                getEmailType(emails){
                    const emailType = Object.keys(emails).find(key => emails[key] === 1);
                    switch (emailType) {
                        case 'is_alternate':
                        return 'Alternate'
                        break;
                        case 'is_cosigner':
                        return 'Cosigner'
                        break;
                        case 'is_emergency':
                        return 'Emergency'
                        break;
                        case 'is_military':
                        return 'Military'
                        break;
                        case 'is_authorized':
                        return 'Authorized'
                        break;
                        case 'is_lien_holder':
                        return 'Lien Holder'
                        break;
                        default:
                        return ''
                        break;
                    }
                },
                resetSpaceMenuOptions(contactObj) {
                    var options = [{
                        id: null,
                        Unit: {
                        number: 'Tenant',
                        type: 'tenant',
                        }
                    }];
                    // below code is to consolidate all the spaces into one array.
                    var allLeases = [];
                    if (contactObj != null && contactObj != undefined) {
                        if (contactObj.Leases != null && contactObj.Leases != undefined && contactObj.Leases.length > 0) {
                            
                            let leases = contactObj.Leases
                            this.leasesDoc = leases
                            allLeases = [...allLeases, ...leases];
                        }
                        
                        let reservations = contactObj.Reservations
                        if (reservations!= null && reservations != undefined &&  reservations.length > 0) {

                        reservations.forEach(obj=>{
                            allLeases.push(obj.Lease)
                        })
                        }
                        
                        let pending = contactObj.Pending
                        if (pending != null && pending != undefined && pending.length > 0) {
                        pending.forEach(obj=>{
                            allLeases.push(obj)
                        })
                        }
                        

                        
                    }

                    if (allLeases != null && allLeases != undefined) {
                        allLeases.forEach(lease => {
                        var updatedValue = {
                            id: lease.id,
                            Unit: {
                            number: lease.Unit.number,
                            type: lease.Unit.type,
                            }
                        }
                        options.push(updatedValue);
                        })
                    }
                    
                    this.mainView = {
                        id: null,
                        Unit: {
                        number: 'Tenant',
                        type: 'tenant',
                        }
                    },
                    this.mainViewOptions = options
      
             }        
        },
        watch: {
            '$route.params.contact_id'(new_contact_id){
                this.clearData();
                this.loadContactInfo(new_contact_id);
                this.checkIsSpaceAccessCodesVendor();
                EventBus.$emit('hide_drawer');
            },
            '$route'() {
                if(this.$route.name === 'ContactOverview'){
                    this.breadcrumbText = 'Back to Previous Page';
                } else {
                    this.breadcrumbText = 'Back to Tenant Profile';
                }
            },
            async propertyView() {
                this.setCurrentProperty(this.propertyView)
                this.pending = [];
                this.payment_methods = [];
                this.fetchPending();
                this.fetchPaymentMethods();
            },
            async getRefreshTenantProfile(value) {
                if(value) {
                    await this.loadTenantDetails();
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.main-content {
    overflow-y: auto;
    overflow-x: hidden;
}
.v-application .pa-0-im {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.sticky-miniprofile {
  display: block !important;
}

.tenant-profile-sticky .backtohistory a.hb-link {
    display: inline-block;
}

.tenant-profile-primary-section-content >>> .hb-communication-background {
        flex:1;
        background: #ffffff;
        position: relative;
        z-index: 1 !important;
    }
.tenant-profile-sticky {
    position: sticky;
    top: 60px;
    z-index: 3;
    background-color: #f9fafb;
}
.tenant-profile-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.tenant-profile-shadow {
    box-shadow: 0 1px 3px rgba(110, 110, 110, 0.27);
}
.no-scroll {
    overflow-y: hidden!important;
}

.section-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0 !important;
    height: calc(100vh - 60px);
    background:rgb(249, 250, 251);
}
.main-section-heading {
    flex-direction: column;
}
.primary-section-content {
    display: flex;
    height: calc(100% - 70px);
    flex-direction: row;
    position: relative;
    overflow-y: hidden;
    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    overflow-x: hidden;
}

    .attached-drawer {
        height: auto;
        background: transparent;
        padding: 0;
        width:0;

        &.theme--light{
            &.v-navigation-drawer{
                background-color:none;
            }
        }
        &.attached-drawer--active {
            width: 400px;
        }
    }
    .tenant-profile-sidebar-wrapper {
        z-index: 3!important;
    }

    .tenant-profile-sidebar-wrapper .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active) {
        background-color: #f9fafb !important;
        border-left: 4px solid #f9fafb;
    }

    .tenant-profile-sidebar-wrapper .v-navigation-drawer__content a.v-list-item--active {
        background-color: #e2f2f3 !important;
        border-left: 4px solid #00848E;
    }

    .billing-information.section, .lease-information.section {
        padding: 20px 20px;
    }

    .billing-information.section .container-fluid > .row {
        border-bottom: 1px solid #e2e2e2;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .billing-information.section .container-fluid > .row:last-child{
        border: none;
    }


    .lease-information.section .lease-img{
        text-align: center;
        margin-top: -100px;
        margin-bottom: 10px;
    }

    .billing-details,
    .lease-details{
        font-size: 13px;
    }
    .lease-details .data-label{
        font-weight: 500;
        color: #abb1b9
    }
    .lease-details .data-value{
        font-weight: 500;
        color: #263238
    }
    .lease-details > .row{
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
    }

    .lease-details > .row:last-child{
        border-bottom: none;
    }
    .billing-details > .row{
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;

    }

    .unit-image {
        height: 135px;
        border: 5px solid white;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 2px;
        width: 240px;
        display: inline-block;
        margin: 10px;
        vertical-align: middle;
        padding: 0px;
        background-color: white;
        box-shadow: 0 0 10px #ccc;
        overflow: hidden;
    }

    .property-light-black{
        color:#677380;
    }

    .property-transparent{
        background: transparent;
    }

    .property-link{
        color:#306FB6;
        text-decoration:none;
    }

    .main-section-heading{
        padding-top:5px;
        padding-bottom:0;
    }

    .dot {
        height: 9px;
        width: 9px;
        margin: 1px 0 0 5px;
        background-color: #02AD0F;
        border-radius: 50%;
        display: inline-block;
    }

    .doterror {
        background-color: #CD2400;
    }

    .row-title {
        font-weight: 500;
    }

    .note-success {
        background-color: #E0F5F5;
        border: 1px solid #b3dee0;
    }

    .note-warning {
        background-color: #F8E5E1;
        border: 1px solid #F3CCCC;
    }

    .lease-message {
        border-radius: 5px;
    }

    .row-title {
        font-weight: 500;
    }

    .loading {
        
        vertical-align: top;
        margin: 25% auto 0;
        .v-progress-circular {
            position: relative;
            top: 40%;
            left: 40%;
        }
    }
    .property-selector-gradient{
    background: repeating-linear-gradient(135deg, #FFD600, #FFD600 5px, #E0BD09 0px, #E0BD09 11px);
  }

    .is-email-container {
        height: calc(100% - 0px);
    }

    @media (min-width: 481px) and (max-width: 1259px) {

    .tenant-profile-sidebar-wrapper {
        position: absolute;
        top: 0px;
    }
}

</style>
